import Worklog from "../models/Worklog";
import { useState } from "react";
import { searchIssue } from "../services/youtrack.service";
import { AutoComplete, CompleteMethodParams } from "primereact/autocomplete";
import dayjs from "dayjs";
import { Button } from "primereact/button";
import Issue from "../models/Issue";
import { Calendar } from "primereact/calendar";
import {
    getTimeDifference,
    getTimeInFormatHoursMinutes,
} from "../services/worklog.service";
import { InputTextarea } from "primereact/inputtextarea";

const WorklogRow = (props: {
    worklogs: Worklog[];
    isLast: boolean;
    worklog: Worklog;
    onChange: Function;
    onDelete: Function;
    onAdd: Function;
}) => {
    const [suggestions, setSuggestions] = useState<Issue[]>([]);

    const { startTime, endTime, description, issue } = props.worklog;

    const { isLast, onChange, onDelete, onAdd } = props;

    function itemTemplate(item: Issue) {
        return <span>{`${item.idReadable} - ${item.summary}`}</span>;
    }

    function updateTime(id: string, time: Date | null) {
        onChange(id, time ? time : null);
    }

    const startTimeDiv = (
        <div className="col-4 col-sm-3 col-md-2 col-lg-1 p-1">
            <Calendar
                id="startTime"
                className="input-time"
                placeholder="--:--"
                value={startTime || undefined}
                onFocus={() => {
                    if (!startTime) {
                        updateTime("startTime", dayjs().toDate());
                    }
                }}
                onSelect={(event) => updateTime("startTime", event.value)}
                onChange={(event) => {
                    if (!event.target.value) {
                        updateTime("startTime", null);
                    } else if (event.target.value instanceof Date) {
                        updateTime("startTime", event.target.value);
                    }
                }}
                stepMinute={5}
                timeOnly
                disabled={props.worklog.hasBeenSent()}
            />
        </div>
    );

    const endTimeDiv = (
        <div className="col-4 col-sm-3 col-md-2 col-lg-1 p-1">
            <Calendar
                id="endTime"
                className="input-time"
                placeholder="--:--"
                value={endTime || undefined}
                onFocus={() => {
                    if (!endTime) {
                        updateTime("endTime", dayjs().toDate());
                    }
                }}
                onSelect={(event) => {
                    updateTime("endTime", event.value);
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        updateTime("endTime", null);
                    } else if (event.target.value instanceof Date) {
                        updateTime("endTime", event.target.value);
                    }
                }}
                stepMinute={5}
                timeOnly
                disabled={props.worklog.hasBeenSent()}
            />
        </div>
    );

    const timeSpentDiv = (
        <div className="col-4 col-sm-2 col-md-1 col-lg-2 p-1 d-flex text-center flex-column justify-content-center time-spent-div">
            <span>
                {getTimeInFormatHoursMinutes(
                    getTimeDifference(startTime, endTime)
                )}
            </span>
        </div>
    );

    const descriptionDiv = (
        <div className="col-sm-12 col-md-10 col-lg-5 p-1">
            <InputTextarea
                className="input-description"
                placeholder="Description"
                value={description}
                onChange={(event: any) =>
                    onChange("description", event.target.value)
                }
                disabled={props.worklog.hasBeenSent()}
                rows={1.5}
                autoResize
            />
        </div>
    );

    const issueDiv = (
        <div className="col-sm-4 col-md-7 col-lg-2 p-1">
            <AutoComplete
                placeholder="Issue"
                value={issue}
                className={"width-100 autocomplete-issue"}
                onChange={(e: {
                    originalEvent: Event;
                    value: any;
                    target: { name: string; id: string; value: any };
                }) => {
                    setSuggestions([]);
                    onChange("issue", e.target.value);
                }}
                disabled={props.worklog.hasBeenSent()}
                suggestions={suggestions}
                field="summaryWithId"
                completeMethod={(e: CompleteMethodParams) =>
                    searchIssue(e.query).then((searchResults: Issue[]) =>
                        setSuggestions(searchResults)
                    )
                }
                tooltip={(issue as any).summary}
                tooltipOptions={{ showDelay: 500 }}
                itemTemplate={itemTemplate}
            />
        </div>
    );

    const buttonsDiv = (
        <div className="col-sm-12 col-md-2 col-lg-1 d-flex flex-row action-buttons">
            <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger p-button-text"
                onClick={(event) => onDelete(event)}
            />
            <Button
                style={{ visibility: isLast ? "visible" : "hidden" }}
                className="p-button-rounded p-button-success p-button-text p-button-icon-only"
                onClick={() => onAdd()}
            >
                <i className="plus-icon pi pi-plus" />
            </Button>
        </div>
    );

    return (
        <div>
            <div className="mid-screen-worklog">
                <div
                    className={
                        "row worklog-row" +
                        (props.worklog.hasBeenSent() ? " has-been-sent" : "") +
                        (props.worklog.hasErrors() ? " has-errors" : "") +
                        (props.worklog.isOverlap(props.worklogs, props.worklog)
                            ? " overlap"
                            : "")
                    }
                >
                    {startTimeDiv}
                    {endTimeDiv}
                    {timeSpentDiv}
                    {issueDiv}
                    {descriptionDiv}
                    {buttonsDiv}
                </div>
            </div>

            <div className="large-screen-worklog">
                <div
                    className={
                        "row worklog-row" +
                        (props.worklog.hasBeenSent() ? " has-been-sent" : "") +
                        (props.worklog.hasErrors() ? " has-errors" : "") +
                        (props.worklog.isOverlap(props.worklogs, props.worklog)
                            ? " overlap"
                            : "")
                    }
                >
                    {startTimeDiv}
                    {endTimeDiv}
                    {timeSpentDiv}
                    {descriptionDiv}
                    {issueDiv}
                    {buttonsDiv}
                </div>
            </div>
        </div>
    );
};

export default WorklogRow;
