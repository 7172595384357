import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputTextarea } from "primereact/inputtextarea";

function WorklogReminder(props: {
    reminder: string;
    onChange: Function;
}) {
    const {reminder, onChange} = props;

    const reminderTitle = 'Reminder';

    function updateReminder(updatedReminder: string) {
        onChange(updatedReminder)
    }

    return (
        <div className="reminder">
            <Accordion activeIndex={0}>
                <AccordionTab header={reminderTitle}>
                    <div className="card">
                        <InputTextarea
                            rows={4}
                            value={reminder}
                            onChange={(event) =>
                                updateReminder(event.currentTarget.value)
                            }
                            placeholder={"Type a reminder"}
                        />
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    );

}

export default WorklogReminder;