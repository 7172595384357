import React, {FunctionComponent, PropsWithChildren} from "react";
import Worklog from "../models/Worklog";
import {
    getOfficeTimeInMinutes,
    getPauseTimeInMinutes,
    getTimeInFormatHoursMinutes,
    getWorkedTimeInMinutes,
    sortWorklogsByStartTime,
} from "../services/worklog.service";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

interface WorklogSummaryProps {
    worklogs: Worklog[];
}

const WorklogSummary: FunctionComponent<WorklogSummaryProps> = (
    props: PropsWithChildren<WorklogSummaryProps>
) => {
    const sortedWorklogs = sortWorklogsByStartTime(props.worklogs);

    const totalOfficeTimeInMinutes = getOfficeTimeInMinutes(sortedWorklogs);
    const totalWorklogTimeInMinutes = getWorkedTimeInMinutes(sortedWorklogs);

    const totalOffice = getTimeInFormatHoursMinutes(totalOfficeTimeInMinutes);
    const totalWorklog = getTimeInFormatHoursMinutes(totalWorklogTimeInMinutes);
    const pauseTime = getTimeInFormatHoursMinutes(
        getPauseTimeInMinutes(totalOfficeTimeInMinutes, totalWorklogTimeInMinutes)
    );

    const summaryTitle = `Summary - (${totalOffice} - ${pauseTime} = ${totalWorklog})`;
    const summary = [
        {
            label: "Total Worklog",
            value: totalWorklog,
        },
        {
            label: "Total Office",
            value: totalOffice,
        },
        {
            label: "Pause Time",
            value: pauseTime,
        },
    ];

    return (
        <div className="summary">
            <Accordion>
                <AccordionTab header={summaryTitle}>
                    <div className="card">
                        <DataTable
                            value={summary}
                            className="p-datatable-gridlines"
                        >
                            <Column field="label"/>
                            <Column field="value"/>
                        </DataTable>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default WorklogSummary;
