import axios, { AxiosResponse } from "axios";
import Worklog from "../models/Worklog";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import Issue from "../models/Issue";

export const searchIssue = (query: string): Promise<Issue[]> => {
    const url = `https://edataconsulting.myjetbrains.com/youtrack/api/issues?fields=id,idReadable,summary&query=+${query}`;
    return axios
        .get<Issue[]>(url)
        .then((response: AxiosResponse<Issue[]>) => response.data.map( value => {
            return {...value, summaryWithId: `${value.idReadable}: ${value.summary}`};
        }));
};

export const addWorklogs = async (date: Date, worklogs: Worklog[]) => {
    const promiseMap = worklogs.map(async (worklog: Worklog) => {
        if (
            worklog.canBeSent() &&
            !worklog.hasBeenSent() &&
            typeof worklog.issue !== "string"
        ) {
            const url = `https://edataconsulting.myjetbrains.com/youtrack/api/issues/${worklog.issue.idReadable}/timeTracking/workItems?fields=id`;
            const startDate = dayjs(worklog.startTime as Date);
            const endDate = dayjs(worklog.endTime as Date);
            const dateNow = dayjs(date).valueOf();
            const data = {
                usesMarkdown: false,
                text: worklog.description,
                date: dateNow,
                duration: {
                    minutes: endDate.diff(startDate, "minutes"),
                },
                created: startDate,
                updated: startDate
            };
            let response;
            let updatedWorklog = cloneDeep(worklog);
            try {
                response = await axios.post(url, data);
                updatedWorklog.externalId = response.data.id;
                updatedWorklog.errors = false;
            } catch (error){
                console.error(`Something happened with the issue ${worklog.issue.idReadable} between ${worklog.startTime} and ${worklog.endTime}`)
                updatedWorklog.errors = true;
            }

            return updatedWorklog;
        }
        return worklog;
    });
    return Promise.all(promiseMap);
};

export const removeWorklogInYouTrack = async (issue: string | Issue, worklogToRemoveExternalId: string | null) => {
    if(typeof issue === "string"){
        return Promise.reject();
    }
    const url = `https://edataconsulting.myjetbrains.com/youtrack/api/issues/${issue.idReadable}/timeTracking/workItems/${worklogToRemoveExternalId}`;
    return axios.delete(url);
}

export const getAuthenticationToken = async (
    username: string,
    password: string
) => {
    const url = `https://edataconsulting.myjetbrains.com/youtrack/api/issues`;
    return axios
        .get(url, {
            auth: {
                username,
                password,
            },
        })
        .then((response) => {
            return response.config.headers.Authorization.split(" ")[1];
        });
};
