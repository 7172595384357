import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {updateLocaleOptions} from "primereact/api";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Add UTC plugin to DayJS
dayjs.extend(utc);
dayjs.extend(customParseFormat);

// Set up Locale options
updateLocaleOptions(
    {
        "firstDayOfWeek": 1,
        "dateFormat": 'dd/mm/yy'
    },
    'en'
);
