import { useEffect, useState } from "react";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import Worklog from "../models/Worklog";
import db, { WorkDay } from "../services/db.service";
import {
    getWorklogTime,
    roundMinutes,
    sortWorklogsByStartTime,
} from "../services/worklog.service";
import WorklogPanel from "./WorklogPanel";
import Header from "./Header";
import WorklogCalendar from "./WorklogCalendar";

function WorklogList(props: { onLogoutSuccess: Function }) {
    const [date, setDate] = useState<Date>(dayjs().toDate());
    const [reminder, setReminder] = useState<string>("");
    const [worklogs, setWorklogs] = useState<Worklog[]>([new Worklog()]);

    useEffect(() => {
        const keypressHandler = (event: any) => {
            if (event.code === "KeyS" && event.ctrlKey && event.shiftKey) {
                setWorklogs(sortWorklogsByStartTime(worklogs));
            }
        };

        window.addEventListener("keypress", keypressHandler);

        return () => {
            window.removeEventListener("keypress", keypressHandler);
        };
    }, [worklogs]);

    useEffect(() => {
        if (date) {
            db.worklogs
                .get(dayjs(date).format("DD/MM/YYYY"))
                .then((workday: WorkDay | undefined) => {
                    if (!isUndefined(workday)) {
                        const work = !isEmpty(workday.worklogs)
                            ? workday?.worklogs.map((worklog: Worklog) =>
                                  Worklog.fromObject(worklog)
                              )
                            : [];
                        setWorklogs(work);
                        setReminder(workday?.reminder);
                    } else {
                        setWorklogs([
                            new Worklog(roundMinutes(getWorklogTime(date))),
                        ]);
                        setReminder("");
                    }
                });
        }
    }, [date]);

    useEffect(() => {
        const getObjectToSave = () => {
            return {
                date: dayjs(date).format("DD/MM/YYYY"),
                reminder: reminder,
                worklogs: worklogs.map((worklog) => Worklog.toObject(worklog)),
            };
        };

        db.worklogs
            .put(getObjectToSave())
            .catch((error: any) => console.log("Error", error));
    }, [date, reminder, worklogs]);

    function logout() {
        props.onLogoutSuccess();
    }

    const [
        isSendingWorklogEntriesToServer,
        setIsSendingWorklogEntriesToServer,
    ] = useState<boolean>(false);

    return (
        <>
            <main>
                <div className="small-screen">
                    <Header
                        date={date}
                        onClick={(updatedDate: any) => {
                            setDate(updatedDate);
                        }}
                        onSelect={(updatedDate: any) => {
                            setDate(updatedDate);
                        }}
                        onClickLogout={() => {
                            logout();
                        }}
                        showCalendarAndButtons={false}
                        isSendingWorklogEntriesToServer={
                            isSendingWorklogEntriesToServer
                        }
                    />
                    <WorklogCalendar
                        date={date}
                        onClick={(updatedDate: any) => {
                            setDate(updatedDate);
                        }}
                        onSelect={(updatedDate: any) => {
                            setDate(updatedDate);
                        }}
                        showWithButtons={true}
                        isSendingWorklogEntriesToServer={
                            isSendingWorklogEntriesToServer
                        }
                    />
                    <div className="container">
                        <WorklogPanel
                            date={date}
                            reminder={reminder}
                            worklogs={worklogs}
                            onUpdate={(
                                updatedWorklogs: any,
                                updatedReminder: string
                            ) => {
                                setWorklogs(updatedWorklogs);
                                setReminder(updatedReminder);
                            }}
                            isSendingWorklogEntriesToServer={
                                isSendingWorklogEntriesToServer
                            }
                            setIsSendingWorklogEntriesToServer={(
                                updatedWorklogs: boolean
                            ) => {
                                setIsSendingWorklogEntriesToServer(
                                    updatedWorklogs
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="mid-screen">
                    <Header
                        date={date}
                        onClick={(updatedDate: any) => {
                            setDate(updatedDate);
                        }}
                        onSelect={(updatedDate: any) => {
                            setDate(updatedDate);
                        }}
                        onClickLogout={() => {
                            logout();
                        }}
                        showCalendarAndButtons={true}
                        isSendingWorklogEntriesToServer={
                            isSendingWorklogEntriesToServer
                        }
                    />
                    <div className="container">
                        <WorklogPanel
                            date={date}
                            reminder={reminder}
                            worklogs={worklogs}
                            onUpdate={(
                                updatedWorklogs: any,
                                updatedReminder: string
                            ) => {
                                setWorklogs(updatedWorklogs);
                                setReminder(updatedReminder);
                            }}
                            isSendingWorklogEntriesToServer={
                                isSendingWorklogEntriesToServer
                            }
                            setIsSendingWorklogEntriesToServer={(
                                updatedWorklogs: boolean
                            ) => {
                                setIsSendingWorklogEntriesToServer(
                                    updatedWorklogs
                                );
                            }}
                        />
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorklogList;
