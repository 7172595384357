import { Message } from "primereact/message";
import Worklog from "../models/Worklog";
import WorklogRow from "./WorklogRow";
import WorkProgressBar from "./WorkProgressBar";
import { Button } from "primereact/button";
import {
    addWorklogs,
    removeWorklogInYouTrack,
} from "../services/youtrack.service";
import set from "lodash/set";
import {
    getWorklogTime,
    getLatestPendingWorklogDayInTheLastMonth,
    isWorklogInWeekend,
    roundMinutes,
    worklogsHasErrors,
    existRangeCollisions,
} from "../services/worklog.service";
import { useMemo, useState } from "react";
import { usePromise } from "../hooks/usePromise";
import WorklogSummary from "./WorklogSummary";
import { confirmPopup } from "primereact/confirmpopup";
import WorklogReminder from "./WorklogReminder"; // To use confirmDialog method

function WorklogPanel(props: {
    date: Date;
    reminder: string;
    worklogs: Worklog[];
    onUpdate: Function;
    isSendingWorklogEntriesToServer: boolean;
    setIsSendingWorklogEntriesToServer: Function;
}) {
    const {
        date,
        reminder,
        worklogs,
        onUpdate,
        isSendingWorklogEntriesToServer,
        setIsSendingWorklogEntriesToServer,
    } = props;

    const [
        latestPendingWorklogDay,
        setLatestPendingWorklogDayInTheLastMonth,
    ] = useState<boolean>(false);

    const latestPendingWorklogDayInTheLastMonth = useMemo(() => {
        return getLatestPendingWorklogDayInTheLastMonth(date);
    }, [date]);

    usePromise(
        latestPendingWorklogDayInTheLastMonth,
        setLatestPendingWorklogDayInTheLastMonth
    );

    const isWeekend = useMemo(() => {
        return isWorklogInWeekend(date);
    }, [date]);

    const worklogsWithErrors = useMemo(() => {
        return worklogsHasErrors(worklogs);
    }, [worklogs]);

    function updateField(index: number, field: string, value: any) {
        const updatedWorklogs: Worklog[] = [...worklogs];

        if (value instanceof Date) {
            set(
                updatedWorklogs[index],
                field,
                roundMinutes(getWorklogTime(date, value))
            );
        } else {
            set(updatedWorklogs[index], field, value);
        }
        onUpdate(updatedWorklogs, reminder);
    }

    function updateReminder(updatedReminder: string) {
        onUpdate(worklogs, updatedReminder);
    }

    function onDeleteEntry(index: number, event: MouseEvent) {
        const updatedWorklogs: Worklog[] = [...worklogs];
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure to delete the worklog?",
            icon: "pi pi-info-circle",
            rejectLabel: "Cancel",
            acceptLabel: "Confirm",
            accept: () => {
                if (updatedWorklogs[index].hasBeenSent()) {
                    removeWorklogInYouTrack(
                        updatedWorklogs[index].issue,
                        updatedWorklogs[index].externalId
                    ).then(() => removeWorklogRow(index, updatedWorklogs));
                } else {
                    removeWorklogRow(index, updatedWorklogs);
                }
            },
        });
    }

    function removeWorklogRow(index: number, worklogsRows: Worklog[]) {
        if (worklogsRows.length === 1) {
            worklogsRows[index].clear(roundMinutes(getWorklogTime(date)));
        } else {
            worklogsRows.splice(index, 1);
        }
        onUpdate(worklogsRows);
    }

    function onNewEntry() {
        onUpdate((previousState: Worklog[]) => {
            let newWorklog = new Worklog();
            const lastWorklog =
                previousState.length > 0
                    ? previousState[previousState.length - 1]
                    : null;

            if (lastWorklog && !lastWorklog.endTime) {
                lastWorklog.endTime = roundMinutes(getWorklogTime(date));
            }

            if (lastWorklog && lastWorklog.endTime) {
                newWorklog.startTime = lastWorklog.endTime;
            }
            return [...previousState, newWorklog];
        });
    }

    function isLast(index: number): boolean {
        return index === worklogs.length - 1;
    }

    const existOverlappedWorklogs = existRangeCollisions(props.worklogs);

    function isSendWorklogEntriesButtonDisabled(): boolean {
        return isSendingWorklogEntriesToServer || existOverlappedWorklogs;
    }

    return (
        <>
            {isWeekend && (
                <div className="row">
                    <div className="col-sm-12 p-1">
                        <Message
                            severity="warn"
                            text="You are currently working in weekend. Check if you have selected the correct date."
                        />
                    </div>
                </div>
            )}
            {latestPendingWorklogDay && (
                <div className="row">
                    <div className="col-sm-12 p-1">
                        <Message
                            severity="warn"
                            text={
                                "You have pending worklog/s to send on " +
                                latestPendingWorklogDay
                            }
                        />
                    </div>
                </div>
            )}
            {worklogsWithErrors && (
                <div className="row">
                    <div className="col-sm-12 p-1">
                        <Message
                            severity="error"
                            text={"Some worklogs couldn't be sent to YouTrack."}
                        />
                    </div>
                </div>
            )}
            <div className="row worklog-panel-labels">
                <div className="col-sm-6 col-md-2 col-lg-1 p-1">
                    <span>Start Time</span>
                </div>
                <div className="col-sm-6 col-md-2 col-lg-1 p-1">
                    <span>End Time</span>
                </div>
                <div className="col-sm-12 col-md-1 col-lg-2 p-1">
                    <span>Time Spent</span>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 p-1">
                    <span>Description</span>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-2 p-1">
                    <span>Issue</span>
                </div>
            </div>
            {worklogs.map((worklog: Worklog, index: number) => (
                <WorklogRow
                    worklogs={worklogs}
                    key={index}
                    worklog={worklog}
                    onChange={(field: string, value: string) =>
                        updateField(index, field, value)
                    }
                    onAdd={() => {
                        onNewEntry();
                    }}
                    onDelete={(event: MouseEvent) =>
                        onDeleteEntry(index, event)
                    }
                    isLast={isLast(index)}
                />
            ))}
            <div className="row">
                <div className="col-sm-12 p-1">
                    <WorklogReminder
                        reminder={reminder}
                        onChange={(updatedReminder: string) =>
                            updateReminder(updatedReminder)
                        }
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 p-1">
                    <WorklogSummary worklogs={worklogs} />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 p-1">
                    <WorkProgressBar
                        worklogs={worklogs}
                        existOverlappedWorklogs={existOverlappedWorklogs}
                    />
                </div>
            </div>
            <div className="row">
                <div className="offset-6 col-6 offset-sm-8 col-sm-4 offset-md-10 col-md-2 p-1">
                    <Button
                        disabled={isSendWorklogEntriesButtonDisabled()}
                        className="send-button"
                        onClick={() => {
                            setIsSendingWorklogEntriesToServer(true);
                            addWorklogs(date, worklogs)
                                .then((updatedWorklogs) => {
                                    onUpdate(updatedWorklogs);
                                })
                                .finally(() => {
                                    setIsSendingWorklogEntriesToServer(false);
                                });
                        }}
                    >
                        <span>
                            <i className="pi pi-check" /> Send
                        </span>
                    </Button>
                </div>
            </div>
        </>
    );
}

export default WorklogPanel;
