import Worklog from "../models/Worklog";
import { FunctionComponent, PropsWithChildren } from "react";
import round from "lodash/round";
import { ProgressBar } from "primereact/progressbar";
import { getWorkedTimeInMinutes } from "../services/worklog.service";

interface WorkProgressBarProps {
    worklogs: Worklog[];
    existOverlappedWorklogs: boolean;
}

const WorkProgressBar: FunctionComponent<WorkProgressBarProps> = (
    props: PropsWithChildren<WorkProgressBarProps>
) => {
    function getPercentage(): number {
        return round((getWorkedTimeInMinutes(props.worklogs) * 100) / 480, 2);
    }

    return (
        <ProgressBar
            value={getPercentage()}
            color={props.existOverlappedWorklogs ? "#FBC02D" : "#C0C0C0"}
        />
    );
};

export default WorkProgressBar;
