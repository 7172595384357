import isEmpty from "lodash/isEmpty";
import isDate from "lodash/isDate";
import dayjs from "dayjs";
import { sortWorklogsByStartTime } from "../services/worklog.service";

export default class Worklog {
    public startTime: Date | null;
    public endTime: Date | null;
    public description: string;
    public issue:
        | string
        | {
              id: string;
              idReadable: string;
              summary: string;
              $type: string;
              summaryWithId: string;
          };
    public externalId: string | null;
    public errors: boolean;

    constructor(startTime?: Date) {
        this.startTime = startTime || null;
        this.endTime = null;
        this.description = "";
        this.issue = "";
        this.externalId = null;
        this.errors = false;
    }

    hasBeenSent() {
        return this.externalId !== null;
    }

    hasErrors() {
        return this.errors;
    }

    isOverlap(worklogs: Worklog[], currentWorklog: Worklog) {
        if (worklogs.length < 1) return false;

        let worklogsTimeIntervals = worklogs.filter(
            (worklog) => worklog.startTime != null && worklog.endTime != null
        );

        if (worklogsTimeIntervals != null && worklogsTimeIntervals.length > 1) {
            for (let i = 0; i < worklogsTimeIntervals.length - 1; i += 1) {
                for (let j = i + 1; j < worklogsTimeIntervals.length; j += 1) {
                    if (
                        this.dateRangeOverlaps(
                            worklogsTimeIntervals[i],
                            worklogsTimeIntervals[j],
                            currentWorklog
                        )
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private dateRangeOverlaps(
        previousWorklog: Worklog,
        nextWorklog: Worklog,
        currentWorklog: Worklog
    ) {
        let previousStart = dayjs(previousWorklog.startTime as Date);
        let previousEnd = dayjs(previousWorklog.endTime as Date);
        let nextStart = dayjs(nextWorklog.startTime as Date);
        let nextEnd = dayjs(nextWorklog.endTime as Date);

        if (nextStart.isAfter(nextEnd) && currentWorklog == nextWorklog) {
            return true;
        }
        if (
            previousStart.isBefore(nextStart) &&
            nextStart.isBefore(previousEnd) &&
            currentWorklog == nextWorklog
        ) {
            return true;
        }
        if (
            previousStart.isBefore(nextEnd) &&
            nextEnd.isBefore(previousEnd) &&
            currentWorklog == nextWorklog
        ) {
            return true;
        }

        return (
            (nextStart.isBefore(previousStart) ||
                nextStart.isSame(previousStart)) &&
            (previousEnd.isBefore(nextEnd) || previousEnd.isSame(nextEnd)) &&
            currentWorklog == nextWorklog
        );
    }

    canBeSent() {
        return (
            isDate(this.startTime) &&
            isDate(this.endTime) &&
            !isEmpty(this.description) &&
            !isEmpty(this.issue) &&
            typeof this.issue !== "string"
        );
    }

    clear(startTime?: Date) {
        this.startTime = startTime || null;
        this.endTime = null;
        this.description = "";
        this.issue = "";
        this.externalId = null;
        this.errors = false;
    }

    static fromObject(dbObject: Worklog): Worklog {
        const newWorklog = new Worklog();
        newWorklog.startTime = dbObject.startTime
            ? dayjs(dbObject.startTime, "DD/MM/YYYY HH:mm").toDate()
            : null;
        newWorklog.endTime = dbObject.endTime
            ? dayjs(dbObject.endTime, "DD/MM/YYYY HH:mm").toDate()
            : null;
        newWorklog.description = dbObject.description;
        newWorklog.issue = dbObject.issue;
        newWorklog.externalId = dbObject.externalId;
        newWorklog.errors = dbObject.errors;
        return newWorklog;
    }

    static toObject(worklog: Worklog): any {
        const dbObject: any = {};
        dbObject.startTime = worklog.startTime
            ? dayjs(worklog.startTime).format("DD/MM/YYYY HH:mm")
            : null;
        dbObject.endTime = worklog.endTime
            ? dayjs(worklog.endTime).format("DD/MM/YYYY HH:mm")
            : null;
        dbObject.description = worklog.description;
        dbObject.issue = worklog.issue;
        dbObject.externalId = worklog.externalId;
        dbObject.errors = worklog.errors;
        return dbObject;
    }
}
