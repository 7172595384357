import Worklog from "../models/Worklog";
import dayjs from "dayjs";
import isDate from "lodash/isDate";
import db, { WorkDay } from "./db.service";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";

export const existRangeCollisions = (worklogs: Worklog[]): boolean => {
    if (worklogs.length < 1) return false;

    const sortedWorklogs = sortWorklogsByStartTime(worklogs);

    const worklogsWithStartAndEndTime = sortedWorklogs.filter(
        (worklog) => !!worklog.startTime && !!worklog.endTime
    );
    for (let i = 0; i < worklogsWithStartAndEndTime.length - 1; i++) {
        const currentEndTime = dayjs(
            worklogsWithStartAndEndTime[i].endTime as Date
        );
        const nextStartTime = dayjs(
            worklogsWithStartAndEndTime[i + 1].startTime as Date
        );

        if (currentEndTime.isAfter(nextStartTime)) {
            return true;
        }
    }

    return false;
};

export const sortWorklogsByStartTime = (worklogs: Worklog[]): Worklog[] => {
    const worklogsWithoutStartTime = worklogs.filter(
        (worklog) => !worklog.startTime
    );
    const worklogsWithStartTimeSortedByStartTime = worklogs
        .filter((worklog) => isDate(worklog.startTime))
        .sort((a: Worklog, b: Worklog) => {
            const startTime1 = dayjs(a.startTime as Date);
            const startTime2 = dayjs(b.startTime as Date);
            return startTime1.isBefore(startTime2) ? -1 : 1;
        });

    return [
        ...worklogsWithStartTimeSortedByStartTime,
        ...worklogsWithoutStartTime,
    ];
};

export const getWorkedTimeInMinutes = (worklogs: Worklog[]): number => {
    return worklogs
        .filter(
            (value: Worklog) => isDate(value.startTime) && isDate(value.endTime)
        )
        .reduce((previousValue: number, currentElement: Worklog) => {
            const startTime = dayjs(currentElement.startTime as Date);
            const endTime = dayjs(currentElement.endTime as Date);
            if (endTime.isBefore(startTime)) {
                return previousValue;
            }
            return previousValue + endTime.diff(startTime, "minutes");
        }, 0);
};

export const getLastWorklogWithEndTimeFilledOut = (
    worklogs: Worklog[]
): Worklog | null => {
    for (let i = worklogs.length - 1; i >= 0; i--) {
        if (worklogs[i].endTime) {
            return worklogs[i];
        }
    }
    return null;
};

export const getOfficeTimeInMinutes = (worklogs: Worklog[]): number => {
    const sortedWorklogs = sortWorklogsByStartTime(worklogs);
    let officeTimeInMinutes = 0;
    if (sortedWorklogs.length > 0) {
        const lastWorklog = getLastWorklogWithEndTimeFilledOut(worklogs);
        if (lastWorklog) {
            officeTimeInMinutes = getTimeDifference(
                sortedWorklogs[0].startTime,
                lastWorklog.endTime
            );
        }
    }
    return Number.isNaN(officeTimeInMinutes) ? 0 : officeTimeInMinutes;
};

export const getPauseTimeInMinutes = (
    totalOfficeTimeInMinutes: number,
    totalWorklogTimeInMinutes: number
): number => {
    const pauseTimeInMinutes =
        totalOfficeTimeInMinutes - totalWorklogTimeInMinutes;
    return pauseTimeInMinutes < 0 ? 0 : pauseTimeInMinutes;
};

export const getTimeDifference = (
    start?: Date | null,
    end?: Date | null
): number => {
    if (!start || !end) {
        return 0;
    }

    const startTime = dayjs(start);
    const endTime = dayjs(end);

    if (endTime.isBefore(startTime)) {
        return 0;
    }

    return endTime.diff(startTime, "minutes");
};

export const getTimeInFormatHoursMinutes = (time: number): string => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return hours + "h " + minutes + "m";
};

export const roundMinutes = (value: Date): Date => {
    const coeff = 1000 * 60 * 5;
    return dayjs(
        Math.round(dayjs(value).toDate().getTime() / coeff) * coeff
    ).toDate();
};

export const getWorklogTime = (date: Date, value?: Date): Date => {
    const now = value ? dayjs(value) : dayjs();
    const dateAsDayJs = dayjs(date);
    return dateAsDayJs.hour(now.hour()).minute(now.minute()).toDate();
};

export const isWorklogInWeekend = (date: Date): boolean => {
    const dateAsDayJs = dayjs(date);
    if (!dateAsDayJs.isValid()) {
        return false;
    }
    return [0, 6].includes(dateAsDayJs.day());
};

export const worklogsHasErrors = (worklogs: Worklog[]): boolean => {
    return worklogs.some((worklog) => worklog.hasErrors());
};

export const getLatestPendingWorklogDayInTheLastMonth = (
    date: Date
): Promise<string | null> => {
    const currentDay = dayjs(date);
    const amountOfDays = 31;
    const promises = [];

    for (let i = 1; i < amountOfDays + 1; i++) {
        const dateToCheck = currentDay
            .set("date", currentDay.date() - i)
            .format("DD/MM/YYYY");
        promises.push(
            db.worklogs
                .get(dateToCheck)
                .then((workday: WorkDay | undefined) => {
                    let work = null;
                    if (!isUndefined(workday) && !isEmpty(workday.worklogs)) {
                        work = workday?.worklogs
                            .map((worklog: Worklog) =>
                                Worklog.fromObject(worklog)
                            )
                            .filter(
                                (worklog) =>
                                    worklog.startTime &&
                                    worklog.endTime &&
                                    !worklog.externalId
                            );
                    }
                    return work;
                })
                .then((work: Worklog[] | null) => {
                    return work && work.length > 0 ? dateToCheck : null;
                })
        );
    }

    return Promise.all(promises).then((worklogsDays: (string | null)[]) => {
        if (worklogsDays.length > 0) {
            const worklogsDaysFiltered = worklogsDays.filter(
                (worklog: any) => !!worklog
            );
            return worklogsDaysFiltered.length !== 0
                ? worklogsDaysFiltered[worklogsDaysFiltered.length - 1]
                : null;
        }
        return null;
    });
};

export const addOneDay = (dateToChange: Date): Date => {
    const date = dayjs(dateToChange);
    return date.set("date", date.date() + 1).toDate();
};

export const subtractOneDay = (dateToChange: Date): Date => {
    const date = dayjs(dateToChange);
    return date.set("date", date.date() - 1).toDate();
};
