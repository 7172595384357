import {useState} from "react";
import {getAuthenticationToken} from "../services/youtrack.service";
import {Message} from "primereact/message";
import { Card } from "primereact/card";

function Login(props: { onLoginSuccess: Function }) {
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });

    const [isUnauthorized, setIsUnauthorized] = useState(false);

    const handleInputChange = (event: any) => {
        setLoginData({
            ...loginData,
            [event.target.name]: event.target.value,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        getAuthenticationToken(loginData.email, loginData.password)
            .then((token: string) => {
                props.onLoginSuccess(token);
            })
            .then(() => {
                setIsUnauthorized(false);
            })
            .catch((err) => {
                setIsUnauthorized(true);
            });
    };

    const header =
        <div>
            <img className="login-image" src="/edataconsulting_easyworklog_white.png" alt="edataconsulting EasyWorklog" />
        </div>;

    const footer = <div/>;

    return (
        <div className="login">
            <Card className="login-card" header={header} footer={footer}>
                <form onSubmit={(event) => onSubmit(event)}>
                    <div className="errors mb-1">
                        {isUnauthorized && (
                            <Message
                                severity="error"
                                text="Username or password are invalid."
                            ></Message>
                        )}
                    </div>
                    <div className="login-inputs-div">
                        <input
                            type="text"
                            name="email"
                            className="form-control mb-1"
                            placeholder="Email"
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            className="form-control mb-1"
                            placeholder="Password"
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="login-submit-button-div">
                        <button className="btn btn-lg login-submit-button" type="submit">
                            Login
                        </button>
                    </div>
                </form>
            </Card>

        </div>
    );
}

export default Login;
