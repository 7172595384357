import { Button } from "primereact/button";
import WorklogCalendar from "./WorklogCalendar";

function Header(props: {
    date: Date;
    onClick: Function;
    onSelect: Function;
    onClickLogout: Function;
    showCalendarAndButtons: boolean;
    isSendingWorklogEntriesToServer: boolean;
}) {
    const {
        date,
        onClick,
        onSelect,
        onClickLogout,
        showCalendarAndButtons,
        isSendingWorklogEntriesToServer,
    } = props;

    return (
        <div className="header">
            <div className="container">
                <div className="row header-content">
                    <div
                        className={`col-sm-6 col-md-9 row-left ${
                            !showCalendarAndButtons ? "no-show-content" : ""
                        }`}
                    >
                        <WorklogCalendar
                            date={date}
                            onClick={onClick}
                            onSelect={onSelect}
                            showWithButtons={true}
                            isSendingWorklogEntriesToServer={
                                isSendingWorklogEntriesToServer
                            }
                        />
                    </div>
                    <div className="col-sm-12 col-md-3 row-right">
                        <Button
                            className="logout"
                            onClick={() => onClickLogout()}
                        >
                            <span className="logout-icon">
                                <i className="pi pi-sign-out" />
                            </span>
                            <span className="logout-text">&nbsp; Logout</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
