import "./App.css";
import WorklogList from "./components/WorklogList";
import Login from "./components/Login";
import {useToken} from "./hooks/useToken";

function App() {
    const {token, setToken} = useToken();

    const onLoginSuccess = (tokenToUpsert: string) => {
        setToken(tokenToUpsert);
    };

    const onLogoutSuccess = () => {
        setToken("");
    };

    if (!token) {
        return (
            <Login onLoginSuccess={(toke: string) => onLoginSuccess(toke)}/>
        );
    }

    return <WorklogList onLogoutSuccess={() => onLogoutSuccess()}/>;
}

export default App;
