import { useState } from "react";
import axios from "axios";

export const useToken = () => {
    const getToken = () => {
        return localStorage.getItem("token");
    };

    const [token, setToken] = useState(getToken());

    let tokenInterceptor: number;
    const upsertTokenInterceptor = (tokenToUpsert: string) => {
        if (tokenInterceptor) {
            removeTokenInterceptor();
        }
        tokenInterceptor = axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = "Basic " + tokenToUpsert;
                return config;
            },
            (err) => {
                if (err.response.status === 401) {
                    setToken("");
                }
                return Promise.reject(err);
            }
        );
    };

    const removeTokenInterceptor = () => {
        axios.interceptors.request.eject(tokenInterceptor);
    };

    if (!!token) {
        upsertTokenInterceptor(token);
    }

    const saveToken = (userToken: string) => {
        if (!userToken) {
            localStorage.removeItem("token");
            removeTokenInterceptor();
        } else {
            localStorage.setItem("token", userToken);
            upsertTokenInterceptor(userToken);
        }
        setToken(userToken);
    };

    return { token, setToken: saveToken };
};
