import { Button } from "primereact/button";
import { addOneDay, subtractOneDay } from "../services/worklog.service";
import dayjs from "dayjs";
import { Calendar } from "primereact/calendar";

function WorklogCalendar(props: {
    date: Date;
    onClick?: Function;
    onSelect: Function;
    showWithButtons: boolean;
    isSendingWorklogEntriesToServer: boolean;
}) {
    const {
        date,
        onClick,
        onSelect,
        showWithButtons,
        isSendingWorklogEntriesToServer,
    } = props;

    return (
        <>
            {showWithButtons && (
                <div className="row calendar">
                    <div className={"col-sm-12 calendar-with-buttons"}>
                        <Button
                            disabled={isSendingWorklogEntriesToServer}
                            icon="pi pi-angle-left"
                            onClick={() => {
                                if (onClick) {
                                    onClick(subtractOneDay(date));
                                }
                            }}
                        />
                        <Calendar
                            disabled={isSendingWorklogEntriesToServer}
                            dateFormat="D dd/mm/yy"
                            className="worklog-calendar"
                            value={date}
                            onSelect={(e: {
                                originalEvent: Event;
                                value: Date;
                            }) => {
                                onSelect(e.value);
                            }}
                            inline={false}
                        />
                        <Button
                            disabled={isSendingWorklogEntriesToServer}
                            icon="pi pi-angle-right"
                            onClick={() => {
                                if (onClick) {
                                    onClick(addOneDay(date));
                                }
                            }}
                        />
                        <Button
                            disabled={isSendingWorklogEntriesToServer}
                            className="today-button"
                            icon="pi pi-calendar"
                            label="Today"
                            tooltip={"Go to today"}
                            tooltipOptions={{
                                className: "today-tooltip",
                                showDelay: 1000,
                            }}
                            onClick={() => {
                                if (onClick) {
                                    onClick(dayjs().toDate());
                                }
                            }}
                        />
                    </div>
                </div>
            )}
            {!showWithButtons && (
                <div className="row">
                    <div className={"col-sm-12 calendar-no-buttons"}>
                        <Calendar
                            disabled={isSendingWorklogEntriesToServer}
                            dateFormat="dd/mm/yy"
                            value={date}
                            onSelect={(e: {
                                originalEvent: Event;
                                value: Date;
                            }) => {
                                onSelect(e.value);
                            }}
                            inline={true}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default WorklogCalendar;
